import React from "react";
import { Box} from "@mui/material";
import SummarizeComponent from "../components/SummarizeComponent";

const Summarization =()=>{
    return(
        <Box>
            <SummarizeComponent/>
        </Box>
    )
}

export default Summarization;