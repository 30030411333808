// constants.js
export const PRICING_TIERS = [
    {
      title: "Free Trial",
      id:5,
      monthly: "Free for 1 month",
      features: [
        "Access to Real-Time Audio/Video Transcription (1 hour)",
        "Access to Document/Text Translation (1000 words)",
        "Access to Text-to-Speech Transformation (1 hour)",
        "Access to Real-Time Speech-to-Speech Translation (1 hour)",
        "Access to Text/Document Summarization (500 words)",
        "Limited Use-Case Solutions and Customization (5 hours)"
      ]
    },
    {
      title: "Classic",
      id:1,
      monthly: "$20/month",
      features: [
        "Real-Time Audio/Video Transcription (25 hours/month)",
        "Document/Text Translation (500 documents/month)",
        "Text-to-Speech Transformation (20 hours/month)",
        "Real-Time Speech-to-Speech Translation (20 hours/month)",
        "Text/Document Summarization (300 documents/month)",
        "Use-Case Solutions and Customization (10 hours/month)"
      ]
    },
    {
      title: "Classic Pro",
      id:2,
      monthly: "$50/month",
      features: [
        "Real-Time Audio/Video Transcription (50 hours/month)",
        "Document/Text Translation (1000 documents/month)",
        "Text-to-Speech Transformation (40 hours/month)",
        "Real-Time Speech-to-Speech Translation (40 hours/month)",
        "Text/Document Summarization (600 documents/month)",
        "Use-Case Solutions and Customization (20 hours/month)"
      ]
    },
    {
      title: "Pro Plus",
      id:3,
      monthly: "$100/month",
      features: [
        "Real-Time Audio/Video Transcription (100 hours/month)",
        "Document/Text Translation (2000 documents/month)",
        "Text-to-Speech Transformation (80 hours/month)",
        "Real-Time Speech-to-Speech Translation (80 hours/month)",
        "Text/Document Summarization (1200 documents/month)",
        "Use-Case Solutions and Customization (40 hours/month)"
      ]
    },
    {
      title: "Enterprise Basic",
      id:4,
      monthly: "Custom pricing",
      features: [
        "Real-Time Audio/Video Transcription (200 hours/month)",
        "Document/Text Translation (5000 documents/month)",
        "Text-to-Speech Transformation (160 hours/month)",
        "Real-Time Speech-to-Speech Translation (160 hours/month)",
        "Text/Document Summarization (3000 documents/month)",
        "Use-Case Solutions and Customization (80 hours/month)"
      ]
    },
    {
      title: "Enterprise Plus",
      id:6,
      monthly: "Custom pricing",
      features: [
        "Unlimited Real-Time Audio/Video Transcription",
        "Unlimited Document/Text Translation",
        "Unlimited Text-to-Speech Transformation",
        "Unlimited Real-Time Speech-to-Speech Translation",
        "Unlimited Text/Document Summarization",
        "Unlimited Use-Case Solutions and Customization",
        "Dedicated account manager",
        "Custom enterprise solutions"
      ]
    }
  ];
  