import React from "react";
import { Box} from "@mui/material";
import TranslationComponent from "../components/TranslationComponent";

const Translation =()=>{
    return(
        <Box>
            <TranslationComponent/>
        </Box>
    )
}

export default Translation;