import React from "react";
import { Box} from "@mui/material";
import HomeComponent from "../components/HomeComponent";

const Dashboard =()=>{
   

    return(
        <Box sx={{width:'100%'}}>
            <HomeComponent/>
        </Box>
    )
}

export default Dashboard;