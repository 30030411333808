import React from "react";
import { Box} from "@mui/material";
import VoiceLingoComponent from "../components/VoiceLingoComponent";
const VoiceLingo =()=>{
    return(
        <Box>
            <VoiceLingoComponent/>
        </Box>
    )
}
export default VoiceLingo;