import React from "react";
import {Box} from '@mui/material'
import SpeechToSpeechForm from "./SpeechToSpeechForm";
const Voice2VoiceComponent =()=>{
    return(   
        <Box>
            
             <Box>
                <SpeechToSpeechForm/>
            </Box>
        </Box>
    )
}

export default Voice2VoiceComponent;