import React from "react";
import { Box} from "@mui/material";
import Text2SpeechComponent from "../components/Text2speechComponent";

const Text2Speech =()=>{
    return(
        <Box>
            <Text2SpeechComponent/>
        </Box>
    )
}

export default Text2Speech;