import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
     'Poppins'
    ].join(','),
    fontSize: 12,
  },
});

export default theme;
