import React from "react";
import LandingPage from "../components/LandingPage";

const Home =()=>{
    return(
        <>
        <LandingPage/>
        </>
    );
}
export default Home;

