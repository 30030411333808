import React from "react";
import { Box} from "@mui/material";
import History from "../components/History";

const Transcribe =()=>{
    return(
        <Box>
            <History/>
        </Box>
    )
}

export default Transcribe;