import React from 'react'
import { Box} from "@mui/material";
import Voice2VoiceComponent from '../components/Voice2VoiceComponent';
const Voice2Voice=()=>{
    return(
        <Box>
            <Voice2VoiceComponent/>
        </Box>
    )
}
export default Voice2Voice;