import React from "react";
import { Box} from "@mui/material";
import SubscriptionComponent from '../components/SubscriptionComponent'

const Subscription =()=>{
    return(
        <Box sx={{width:'100%'}}>
            <SubscriptionComponent/>
        </Box>
    )
}
export default Subscription;