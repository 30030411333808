import React from "react";
import { Box} from "@mui/material";
import ContactComponent from "../components/ContactComponent";

const ContactSupport =()=>{
    return(
        <Box>
            <ContactComponent/>
        </Box>
    )
}

export default ContactSupport;